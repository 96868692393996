import styles from './styles.module.css';
import React, { Component } from 'react';

const MinAMinNota = (props) =>  {
    
    const {
        title,
        text,
        url,
        clase,
        clase_equipo,
        isPreview,
        url_iframe
    } = props;
    
    let nombre_clase = "container-"+clase; 
    let nombre_clase_equipo = "container-equipo-"+clase_equipo;
  return (
<div className={isPreview ? styles['preview-containter']+" "+styles[nombre_clase]+" "+ styles[nombre_clase_equipo]+" "  : styles[nombre_clase]+" "+styles[nombre_clase_equipo] }> 
            <div className={styles['c1minamin']}>
                <div className={styles['content-c1mam-sabalera']}>
                <span className={styles['btn-el-minamin-sabalera']}><a href="https://www.ellitoral.com/um/sabalera" >Actualidad Sabalera</a></span>
                <span className={styles['estadisticas']}><a href='https://www.ellitoral.com/seccion/deportes/tablas/primeraa/fixture'>Tablas &bull; Resultados &bull; Posiciones</a></span> 
                </div>
                <div className={styles['content-c1mam-tatengue']}>
                <span className={styles['btn-el-minamin-tatengue']}><a href="https://www.ellitoral.com/um/tatengue" >Actualidad Tatengue</a></span>
                <span className={styles['estadisticas']}><a href='https://www.ellitoral.com/seccion/deportes/tablas/primeraa/fixture'>Tablas &bull; Resultados &bull; Posiciones</a></span> 
                </div>
                 
                <div className={styles['sobreimg']}>  
                <a href={url}><h1><strong>Minuto a minuto</strong> {title}</h1></a>
                <span className={styles['bajada-minamin']}>{text}</span>
                </div>
                <div className={styles['publi-mam']}>
                    <div className='ad-slot' id='mam' data-adtype='sponsor_fijo'> </div>
                </div>    
            </div>
   
            <div className={styles['c2minamin']}>
            <div className={styles['contenedor-escudo']}></div>
                <div className={styles['embebed-minamin']}> 
                    <iframe
                        src={url_iframe}
                        height="118"
                        frameborder="0"
                        allowfullscreen
                        scrolling="no"
                        sandbox>
                    </iframe>
                </div>             
            </div>
  
    <div className={styles['c3minamin']}></div>
    
    </div>

    )
}
export default MinAMinNota;
